// This filter is used to filter images, products and videos
// all items must have a categories field (array)

export const filterItems = (filter, items, language) => {
  if (filter.length > 0) {
    // check if the filter holds a species cat
    if (filter.filter(f => f.parent === "Species").length > 0) {
      // if yes, select the Species filter
      const speciesFilter = filter.filter(f => f.parent === "Species")

      // if yes, preselect items with that species cat
      // 1. filter by the Species category
      // 2. push results in preselected array (didn't work with filetering on the same array)

      const preselectedItems = []
      items.filter(i => {
        // need to check if i.node exists, since the structure of the
        // image and the product/video schmemas is different
        if (i.node) {
          return i.node.categories.filter(c => {
            if (c._rawTitle.en === speciesFilter[0].label) {
              preselectedItems.push(i)
            }
          })
        }
        return i.categories.filter(c => {
          if (c._rawTitle.en === speciesFilter[0].label) {
            preselectedItems.push(i)
          }
        })
      })

      // run the match on that preselectd batch
      return preselectedItems.filter(img => {
        // check where the filter and the categories of the image match
        // create an array with true/false values
        let match = []

        // again, need to check if the .node field exists
        // due to the differrent structure of the image and the prod/video schemas
        if (img.node) {
          match = img.node.categories.map(
            cat =>
              filter.map(item => item.label).indexOf(cat._rawTitle[language]) >
              -1
          )
        } else {
          match = img.categories.map(
            cat =>
              filter.map(item => item.label).indexOf(cat._rawTitle[language]) >
              -1
          )
        }

        // if there is a single filter, match any (some) categories on the image
        if (filter.length === 1) {
          return match.some(i => i === true) && img
        }

        // if there are multiple filter items, match every category on the image
        return match.every(i => i === true) && img
        // otherwise run the normal filter
      })
    }
  } else {
    return items
  }
}
