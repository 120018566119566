import React from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MediaQueries } from "../../utils/responsive"
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs"

const StyledLeftArrow = styled(BsArrowLeftShort)`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 7%;
  color: ${props => props.theme.colors.white};
  font-size: 5rem;
  border: 2px solid white;
  border-radius: 50%;
  @media ${MediaQueries.mobileUp} {
    left: 4%;
  }
  &:hover {
    background-color: ${props => props.theme.colors.whiteOp};
  }
`

const StyledRightArrow = styled(BsArrowRightShort)`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  transform: translate(-50%, -50%);
  right: -6%;
  top: 50%;
  color: ${props => props.theme.colors.white};
  font-size: 5rem;
  border: 2px solid white;
  border-radius: 50%;
  @media ${MediaQueries.mobileUp} {
    right: 1%;
  }
  &:hover {
    background-color: ${props => props.theme.colors.whiteOp};
  }
`

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  max-width: 100% !important;

  margin-top: 15rem;
  @media ${MediaQueries.mobileUp} {
    margin-top: 0;
    margin: 1rem;
    max-height: 90%;
  }
  .modal-content {
    background: ${props => props.theme.colors.black};
  }
  .modal-title {
    color: ${props => props.theme.colors.white};
    font-size: 1.6rem;
  }
  .modal-body {
    padding: 0;
    @media ${MediaQueries.mobileUp} {
      padding: 15px;
    }
  }
  .close {
    font-size: 3rem;
    color: ${props => props.theme.colors.white};
    font-weight: normal;
  }
`

const PhotosModal = ({
  modal,
  toggle,
  modalImage,
  language,
  turnImageLeft,
  turnImageRight,
}) => {
  return (
    <StyledModal isOpen={modal} toggle={toggle} className={"photo-modal"}>
      <ModalHeader toggle={toggle}>
        {modalImage._rawTitle
          ? `${modalImage?._rawTitle?.[language]}`
          : "Justanatomy picture"}
        {modalImage?.categories.map(c => (
          <span>{` | ${c._rawTitle[language]}`}</span>
        ))}
      </ModalHeader>
      <ModalBody>
        <GatsbyImage
          image={getImage(modalImage.image.asset)}
          alt={modalImage.title || "Justanatomy picture"}
        />
        <StyledLeftArrow onClick={e => turnImageLeft(modalImage)} />
        <StyledRightArrow onClick={e => turnImageRight(modalImage)} />
      </ModalBody>
    </StyledModal>
  )
}

export default PhotosModal
