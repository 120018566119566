import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Navbar2 from "../components/Navbar2"
import CategorySelector from "../components/CategorySelector"
import PhotosModal from "../components/PhotosModal"
import { useTranslation } from "react-i18next"
import { MediaQueries } from "../utils/responsive"
import "@fontsource/ibm-plex-serif/300-italic.css"
import {
  BsFillGrid3X3GapFill,
  BsFillGridFill,
  BsFillSquareFill,
} from "react-icons/bs"
import { filterItems } from "../utils/filterItems"

const StyledPhotoPage = styled(Container)`
  margin-top: 80px;
  margin-bottom: 80px;
  @media ${MediaQueries.laptopUp} {
    margin-top: 0;
  }

  .styledGridSelector {
    display: none;
    list-style: none;
    margin: 0;
    margin-bottom: 2rem;
    @media ${MediaQueries.tabletUp} {
      display: block;
      margin-left: 10rem;
    }

    li {
      display: inline;
      margin-right: 1rem;
      &:hover {
        cursor: pointer;
      }
      svg {
        font-size: 24px;
        &:hover {
          color: ${props => props.theme.colors.yellow};
        }
      }
    }
  }
  .imageContainer {
    position: relative;
    .image-holder {
      position: relative;
    }
    .text-holder {
      position: absolute;
      bottom: 0;
      left: 0;
      max-height: 35%;
      background-color: ${props => props.theme.colors.purple};
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 2rem;
      span {
        color: ${props => props.theme.colors.white};
        font-family: "Poppins";
        font-size: 1.4rem;
        text-transform: uppercase;
        padding: 0.5rem 0;
      }
    }
  }
  .divider {
    display: flex;
    align-items: center;
    z-index: 1;
    position: absolute;
    width: 100%;
    margin: 0;
    margin-top: -23px;
    .dividerText {
      margin: 0;
      padding: 0 1rem;
      font-family: "IBM Plex Serif";
      font-weight: 300;
      font-style: italic;
      font-size: 28px;
    }
    .line {
      height: 3px;
      background-color: ${props => props.theme.colors.white};
      flex: 1;
    }
  }
`

const PhotoPage = props => {
  const { i18n } = useTranslation("blog")
  const language = i18n.language

  const images = props.data.photos.edges[0].node.images
  const [imagePerRow, setImagePerRow] = useState(1)

  const [stateImages, setStateImages] = useState(images)

  const [modal, setModal] = useState(false)
  const [modalImage, setModalImage] = useState(images[0])

  const toggle = () => setModal(!modal)

  const setImage = image => {
    setModalImage(image)
    toggle()
  }

  const turnImageLeft = image => {
    const index = stateImages.findIndex(
      i => i.image.asset._id === image.image.asset._id
    )
    const next = index === 0 ? stateImages.length - 1 : index - 1
    setModalImage(stateImages[next])
    return next
  }

  const turnImageRight = image => {
    const index = stateImages.findIndex(
      i => i.image.asset._id === image.image.asset._id
    )
    const next = index === stateImages.length - 1 ? 0 : index + 1
    setModalImage(stateImages[next])
    return next
  }

  const updateFilter = filter =>
    setStateImages(filterItems(filter, images, language))

  return (
    <Layout alternateLink={props.alternateLinks}>
      <SEO title="Justanatomy.com | Photos" />
      <StyledPhotoPage>
        <Row>
          <Col xs={12} sm={6}>
            <ul className="styledGridSelector">
              <li onClick={() => setImagePerRow(1)}>
                <BsFillSquareFill />
              </li>
              <li onClick={() => setImagePerRow(2)}>
                <BsFillGridFill />
              </li>
              <li onClick={() => setImagePerRow(4)}>
                <BsFillGrid3X3GapFill />
              </li>
            </ul>
          </Col>
          <Col sm={12} sm={6}>
            <CategorySelector
              language={language}
              categories={props.data.categories.edges}
              updateFilter={updateFilter}
            />
          </Col>
        </Row>
        <Row className="mt-4 justify-content-end"></Row>
        <Row>
          {stateImages &&
            stateImages.map((image, i) => (
              <Col xs={12} lg={12 / imagePerRow} className="mb-5">
                <div className="imageContainer h-100" key={image._id}>
                  <div className="image-holder h-100">
                    <GatsbyImage
                      image={getImage(image.image.asset)}
                      className="h-100"
                      onClick={() => setImage(image)}
                      style={{ cursor: "pointer" }}
                    />

                    {image._rawDescription ? (
                      <div className="text-holder">
                        <span>{image._rawDescription[language]}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </StyledPhotoPage>
      <Container fluid>
        <Row>
          <Col xs={12} className="px-0 position-relative">
            <Navbar2 fixed={true} topNav={false} narrow={true} />
          </Col>
        </Row>
      </Container>

      <PhotosModal
        modal={modal}
        toggle={toggle}
        modalImage={modalImage}
        language={language}
        turnImageLeft={turnImageLeft}
        turnImageRight={turnImageRight}
      />
    </Layout>
  )
}
// TODO maxHeight: 500px
export const query = graphql`
  query PhotoPageQuery($language: String) {
    photos: allSanityGallery {
      edges {
        node {
          images {
            categories {
              _id
              _rawTitle
              _rawSlug
              parents {
                title {
                  en
                }
              }
            }
            _rawDescription
            image {
              asset {
                _id
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
    categories: allSanityCategory {
      edges {
        node {
          title {
            translate(language: $language)
          }
          _rawSlug
          parents {
            _rawTitle
          }
        }
      }
    }
  }
`
export default PhotoPage
